import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Over mij" />
    <h1>Over mij</h1>
    <p>
      Al 14 jaar maak ik met plezier <b>websites en apps</b> die{" "}
      <b>prettig zijn in gebruik</b>. Sinds 2016 focus ik me voornamelijk op
      apps, en heb hier inmiddels een redelijk expertise in opgebouwd.
    </p>

    <p>
      Ik denk graag mee over oplossingen die niet alleen technisch hoogstaand
      zijn, maar ook aansluiten bij jouw organisatie.
    </p>

    <img
      alt="Dit ben ik"
      style={{ width: 100, height: 100 }}
      src={require("../images/me.png")}
    />
  </Layout>
)

export default SecondPage
